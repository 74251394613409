import React, {useState, useContext} from "react";
import { Routes, Route } from 'react-router-dom';
import HomeEn from "./pages/home/english";
import HomeEs from "./pages/home/spanish";
import ContactEn from "./pages/contactus/english";
import ContactEs from "./pages/contactus/spanish";
import LegalEn from "./pages/disclosures/english";
import LegalEs from "./pages/disclosures/spanish";
import FLEn from "./pages/finanacial_literacy/english";
import FLEs from "./pages/finanacial_literacy/spanish";
import FaqEn from "./pages/faqs/english";
import FaqEs from "./pages/faqs/spanish";
import "./styles/style.css"

// class App extends React.Component {
const App = () => {
  
  // render = () => {
    return (
        <Routes>
          <Route path="/" element={<HomeEn />} />
          <Route path="/es" element={<HomeEs />} />
          <Route path="/contactus" element={<ContactEn />} />
          <Route path="/es/contactus" element={<ContactEs />} />
          <Route path="/disclosures" element={<LegalEn />} />
          <Route path="/es/disclosures" element={<LegalEs />} />
          <Route path="/financial-literacy" element={<FLEn />} />
          <Route path="/es/financial-literacy" element={<FLEs />} />
          <Route path="/FAQs" element={<FaqEn />} />
          <Route path="/es/FAQs" element={<FaqEs />} />
        </Routes>
    );
  }
// }

export default App;
