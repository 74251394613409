import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/spanish";

import getStarted from "../../assets/images/get_started.png";
import m1 from "../../assets/images/masonary/m12.jpg";
import s from "../../assets/images/s.png";
import t from "../../assets/images/t.png";
import u from "../../assets/images/u.png";
import v from "../../assets/images/v.png";
import whynuestro1 from "../../assets/images/product_es.png";
import whynuestro2 from "../../assets/images/ssnitin_es.png";
import whynuestro3 from "../../assets/images/bilingual_es.png";
import whynuestro4 from "../../assets/images/culture_es.png";
import banner_bg from "../../assets/images/banner_bg_copy.png";
import mobile_banner_bg from "../../assets/images/banner_bg.png";
import banner from "../../assets/images/home_loan_banner.jpg";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/spanish";
import click from "../../assets/gifs/click.webm";

const HomeEs = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isMacOS = /Macintosh/.test(navigator.userAgent);

  const shouldRenderVideo = !isIOS && !isMacOS;

  return (
    <div>
      <NavbarEn />
      <div>
        <Container fluid>
          <Row>
          <Col sm={12} md={6} xs={12} className="ps-5">
              <div>
                <img src={banner} width="100%" />
              </div>
            </Col>
            <Col sm={12} md={6} xs={12} style={{ zIndex: 50 }}>
              <div className="banner_text">
                <h1>
                Préstamos Hipotecarios<br/> que funcionan para usted 
                </h1>
                <p>¿Desea comprar su primera casa?<br/> ¿Buscas renovar la casa que ya tienes?<br/> Cualquiera que sea su objetivo, le ayudaremos a encontrar el préstamo adecuado para usted. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container fluid>
          <Row>
            <Col className="home_background">
              <div className="white_background">
                <h1>Bienvenido a ser propietario de una vivienda </h1>
                <p>Comprar una casa es un paso ENORME.<br/> Descubra acciones claves que le ayudarán a prepararse para este importante paso. </p>
                <p>Preparación. Precalificación. Propuesta. Evaluación. Clausura</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
     

      {/* <Container className="fl_home">
        <Row>
          <h1>Free Financial Education Workshops</h1>
          <p>Welcome to Nuestro's Free Financial Education Workshops, empowering communities with essential financial knowledge every Monday, 7:00 p.m. - 8:00 p.m. at 444 Metroplex Dr. Suite 225-B. </p>
          <div style={{textAlign:'center', marginBottom:'10px'}}>
            <img src={m1} style={{width:'320px', borderRadius:'30px'}}/>
          </div>
          <h4>Take control of your financial future with us!</h4>
          <div style={{textAlign:'center', padding:'30px 40px'}}>
            <a href="/financial-literacy">Know More</a>
          </div>
        </Row>
      </Container> */}
      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container fluid style={{backgroundColor:'#ffffff'}}>
          <Row
            className="pe-lg-5 pt-5 pb-5 slide-in-right"
            style={{ textAlign: "right" }}
          >
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "rgb(232,0,120)",
              }}
            >
              La Diferencia  
              <span
                style={{
                  color: "rgb(33, 67, 136)",
                  fontFamily: "RockoUltraflf",
                }}
              >
                {" "}
                de Nuestro 
              </span>{" "}
            </h1>
          </Row>
          <Row className="pb-5" style={{ textAlign: "center"}}>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro1}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro2}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro3}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro4}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
      </Container>
      <Container fluid>
          <Row className="py-5">
            <Col className="py-5 choose_nuestro mx-5 px-lg-5">
              <h1>¿Porque escoger Nuestro? </h1>
              <p>Somos un equipo de profesionales hipotecarios dedicados con experiencia, comprometidos a ofrecer productos crediticios de calidad, con integridad y servicios sin precedentes. Estas son sólo algunas de las formas en las que se beneficiará al elegir a Nuestro Financial como su socio. </p>
              <Row style={{textAlign:'center'}}>
                  <Col md={3} sm={6} className="px-2">
                    <img src={s} width="50%" className="py-3"/>
                    <h4>Personalización </h4>
                    <p>Nuestros asesores de préstamos ofrecen soluciones con un enfoque personalizado. </p>
                  </Col>
                  <Col md={3} sm={6} className="px-2">
                    <img src={t} width="50%" className="py-3"/>
                    <h4>Selección </h4>
                    <p>Le ofrecemos una amplia gama de productos crediticios, ofreciendo una solución personalizada. </p>
                  </Col>
                  <Col md={3} sm={6} className="px-2">
                    <img src={u} width="50%" className="py-3"/>
                    <h4>Solución </h4>
                    <p>También contamos con un conjunto de productos especializados que nos permiten personalizar una solución que se ajuste a su situación particular. </p>
                  </Col>
                  <Col md={3} sm={6} className="px-2">
                    <img src={v} width="50%" className="py-3"/>
                    <h4>Asistencia</h4>
                    <p>Para ayudar con los costos iniciales, ofrecemos productos con asistencia para el pago inicial. </p>
                  </Col>
              </Row>
            </Col>
          </Row>
          <Row className="tile_background" fluid>
            <Col md={7} sm={12}>
              <h1>¿Listo para comenzar su aventura de la Compra de una Casa?  </h1>
              <p>Póngase en contacto con nuestro equipo hoy para comenzar a conseguir la casa de sus sueños. </p>
              <a href="tel:(615) 800-6181"><button>Llámenos </button></a>
            </Col>
            <Col md={5} sm={12}>
              <img src={getStarted} width="100%"/>
            </Col>
          </Row>

          {/* <Row className="p-5 slide-in-left">
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "#ffffff",
              }}
            >
              How does it work?
            </h1>
          </Row> */}
          {/* <Row className="pe-5 ps-5">
            <Col
              className="p-5 slide-in-right"
              style={{
                backgroundColor: "#ffffff",
                color: "rgb(33, 67, 136)",
                borderRadius: "30px",
              }}
            >
              <p>
                1. Click the Apply Now button to get started! 
              </p>
              <p>
                2. Choose the appropriate credit product, the amount, and the
                repayment term you're looking for. Our products have different
                uses, so make sure you pick the right one. 
              </p>
              <ul>
                <li>Prestamo Migrante: Opportunities for new clients starting at $500! Apply easily to find out how much you can start with</li>
                <li>Prestamo Premier: Only for our existing Premier customers.</li>
              </ul>
              <p>
                3. For all loans, you're going to need the following documents:
              </p>
              <ul>
                <li>Photo ID</li>
                <li>Proof of Domicile</li>
                <li>Proof of Employment / Pay</li>
                <li>Three Most Recent Bank Statements</li>
                <li>Valid Bank Account</li>
              </ul>
              <p>
                4. Once approved and signed, you will receive the money via
                Direct Deposit into your connected bank account within
                approximately 48 hours.
              </p>
              <p>
                5. Improve your credit score! By meeting your monthly payments,
                vas a conseguir un mejor credit score. Nuestro Loans reports
                to Clarity, which is a part of Experian, a credit bureau,
                ayudandote a mejorar tu credit profile. En nuestro loans NO
                HAY prepayment fees, so you can pay it off early without extra
                fees.
              </p>
              <p style={{ fontSize: "10px" }}>
                *You must have the necessary documentation to apply for these
                loans.
                <br />
                **If you don’t have a bank account check www.nuestrowallet.com ,
                we may be able to help you.
              </p>
            </Col>
          </Row> */}
          {/* <Row className="p-5" style={{ textAlign: "center" }}>
            <div>
              <a
                href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                className="pt-3 pb-3 apply-button grow-effect"
              >
                Apply Now
              </a>
            </div>
          </Row> */}
        </Container>
      </div>
      <FooterEn />
    </div>
  );
};

export default HomeEs;
